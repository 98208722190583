<template>
    <div class="tfrSignUpsDashboard">
        <div class="tfrSignUpsDashboard__header">
            <div class="tfrSignUpsDashboard__title">The FLY Report Sign Ups</div>
        </div>
        <div class="tfrSignUpsDashboard__button">
          <span class="text" @click="exportToExcel">Export</span>
        </div>
    </div>
</template>

<script>
import './TfrSignUps.styl'
import { reportsAPI } from '@/api/reports';

export default {
    name: 'tfr-sign-ups',
    methods: {
      async exportToExcel () {
        try {
          const { data } = await reportsAPI.ctaEmails()
          const downloadLink = document.createElement('a');
          const blob = new Blob(['\ufeff', data]);
          const url = window.URL.createObjectURL(blob);
          downloadLink.href = url;
          downloadLink.download = 'tfr_sign_ups_report_' + new Date().toLocaleDateString().replaceAll('/', '-') + '.csv';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } catch (e) {
          console.log(e)
        }
      },
    },
}
</script>